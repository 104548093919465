import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"feedback"} />
		<Helmet>
			<title>
				Feedback | Японська Кулінарна Академія: Смак Східного Сонця
			</title>
			<meta name={"description"} content={"Відгуки наших учнів про курс і їх досвід навчання:"} />
			<meta property={"og:title"} content={"Feedback | Японська Кулінарна Академія: Смак Східного Сонця"} />
			<meta property={"og:description"} content={"Відгуки наших учнів про курс і їх досвід навчання:"} />
			<link rel={"shortcut icon"} href={"https://ritas-flet.net/img/5900160.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ritas-flet.net/img/5900160.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ritas-flet.net/img/5900160.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ritas-flet.net/img/5900160.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ritas-flet.net/img/5900160.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ritas-flet.net/img/5900160.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 56px 0px"
				width="100%"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline1"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Відгуки
				</Text>
				<Text margin="0px 0px 0px 0px" color="--lightD2" text-align="center" font="--lead">
					Відгуки наших учнів про курс і їх досвід навчання:
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				align-items="stretch"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text
						margin="0px 0px 100px 0px"
						color="--lightD2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Японська Кулінарна Академія - це справжня знахідка для любителів японської кухні! Я відвідувала курси з приготування суші та сашимі, і можу сказати, що це був неймовірний досвід
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--light" font="--base" margin="0px 0px 2px 0px">
								Марія З.
							</Text>
							<Text color="--lightD2" font="--base" margin="0px 0px 0px 0px" opacity=".5">
								3 потік
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 0px 0px 15px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text
						margin="0px 0px 100px 0px"
						color="--lightD2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Я дуже задоволений курсами! Уроки були інформативними та цікавими. Інструктори не лише вчать готувати, а й діляться історичними відомостями та культурним контекстом кожної страви. Тепер я можу створювати автентичні японські страви та пригощати друзів та родину. Це чудово!
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--light" font="--base" margin="0px 0px 2px 0px">
								Олексій П.
							</Text>
							<Text color="--lightD2" font="--base" margin="0px 0px 0px 0px" opacity=".5">
								1 потік
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					lg-margin="40px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text
						margin="0px 0px 100px 0px"
						color="--lightD2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Ці курси дійсно допомогли мені зрозуміти і полюбити японську кухню. Інструктори були просто чудовими, завжди готовими допомогти і підказати. Дякую, академію, за унікальне мистецтво приготування японських страв!
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--light" font="--base" margin="0px 0px 2px 0px">
								Ольга К.
							</Text>
							<Text color="--lightD2" font="--base" margin="0px 0px 0px 0px" opacity=".5">
								1 потік
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					lg-padding="0px 0px 0px 15px"
					lg-margin="40px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text
						margin="0px 0px 100px 0px"
						color="--lightD2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Я завжди мріяла навчитися готувати суші та сашими, і саме тут мої мрії здійснилися. Уроки були інформативними та інтерактивними, а інструктори справді майстри своєї справи. Тепер я можу готувати автентичні японські страви в затишку своєї затишки. і вражати друзів смаком! Велике спасибі команді академії за цей незабутній досвід!
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--light" font="--base" margin="0px 0px 2px 0px">
								Ірина М.
							</Text>
							<Text color="--lightD2" font="--base" margin="0px 0px 0px 0px" opacity=".5">
								4 потік
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6523ace1e2dd3a0022204083"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});